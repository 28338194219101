import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services';

/// items without grouping
export const getBrowseItemsProducts = createAsyncThunk(
    'redux/getBrowseItemsProducts',
    async ({ auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd, itemType, categoryId, itemFlag, searchText, userId, adminPreview=false }, {getState}) => {
        let response;
        const state = getState().browseItems;
        switch (itemType) {
            case "event-byNow":
                response = await auctionServices.getBuyNowItemsByAuctionId(auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd, searchText, userId, adminPreview, state.selectedBrowseEventFilter)
                break;
            case "event-auction":
                response = await auctionServices.getAuctionItemByAuctionId(auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd, searchText, userId, state.selectedBrowseEventFilter)
                break;
            case 'marketplace':
                response = await auctionServices.getAuctionItemsById(auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd,null, searchText, userId, adminPreview);
                break;
            case 'event':
                response = await auctionServices.getAuctionItemsById(auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd,null, searchText, userId, adminPreview);
                break;
            case 'store':
                response = await auctionServices.getAuctionItemsById(auctionId, page, limit, sortBy, priceRangeStart, priceRangeEnd, itemType, searchText, userId, adminPreview, state.selectedBrowseEventFilter);
                break;
            case 'event-category':
                response = await auctionServices.getAuctionItemsByCategoryId(page, limit, categoryId, auctionId, sortBy, priceRangeStart, priceRangeEnd, itemFlag, userId, adminPreview, state.selectedBrowseEventFilter);
                break;
            case 'event-custom-category':
                response = await auctionServices.getItemsByCustomCategoryByAuctionId(auctionId, categoryId, page, limit, sortBy, priceRangeStart, priceRangeEnd, itemFlag, userId, true, adminPreview, state.selectedBrowseEventFilter)// true is for check by category_name instead of id
                break;
            case 'browse-category':
                response = await auctionServices.getAuctionItemsByCategoryId(page, limit, categoryId, null, sortBy, priceRangeStart, priceRangeEnd, null, userId, adminPreview);
                break;
            case 'cause-category':
                response = await auctionServices.getAllItemsByCauseCategory(page, limit, categoryId, sortBy, priceRangeStart, priceRangeEnd, userId)
                break;
            default:
                break;
        }
        return response
    }
)

//// items with grouping
export const getGroupedBrowseItems = createAsyncThunk(
    'redux/getGroupedBrowseItems',
    async ({ sortBy, page, limit, groupBy, auctionId, itemType, priceRangeStart, priceRangeEnd, categoryId, userId, adminPreview=false }, {getState}) => {
        let response
        const state = getState().browseItems;
        if(auctionId){
            response = await auctionServices.fetchItemsByFiltering(sortBy, page, limit, groupBy, auctionId, itemType, priceRangeStart, priceRangeEnd, categoryId, userId, adminPreview, state.selectedBrowseEventFilter)
        }
        else {
            response = await auctionServices.fetchItemsByFilteringWithoutAuctionId(sortBy, page, limit, groupBy, itemType, priceRangeStart, priceRangeEnd, categoryId, userId, adminPreview, state.selectedBrowseEventFilter)
        }
        return response
    }
)

const initialState = {
    itemList: [],
    totalCount: 0,
    totalPage: 0,
    loading: false,
    error: '',
    selectedBrowseEventFilterObj: { brands: [] },
    selectedBrowseEventFilter: {  brands: [] },
}

const browseItemsSlice = createSlice({
    name: 'browseItems',
    initialState,
    reducers: {
        resetBrowseItems: (state, action) => {
            state.itemList = []
            state.loading = false
            state.totalCount = 0
            state.totalPage = 0
            state.error = ''
        },
        setBrowseBySelectedFilters: (state, action) => {
            if(!state.selectedBrowseEventFilter[action.payload.key]?.includes(action.payload.value.id)) {
                state.selectedBrowseEventFilter[action.payload.key].push(action.payload.value.id)
                state.selectedBrowseEventFilterObj[action.payload.key].push({
                    id: action.payload.value.id,
                    name: action.payload.value.name,
                    valueType: action.payload.valueType
                })
            }
            else {
                state.selectedBrowseEventFilter[action.payload.key] = state.selectedBrowseEventFilter[action.payload.key].filter((id) => id !== action.payload.value.id)
                state.selectedBrowseEventFilterObj[action.payload.key] = state.selectedBrowseEventFilterObj[action.payload.key].filter((obj) => obj.id !== action.payload.value.id)
            }
            state.page = 1
        },
        clearAllBrowseBySelectedFilters: (state, action) => {
            state.selectedBrowseEventFilterObj = { brands: [] }
            state.selectedBrowseEventFilter = {  brands: [] }
        },
        clearBrowseBySelectedFilters: (state, action) => {
            state.selectedBrowseEventFilter[action.payload.key] = state.selectedBrowseEventFilter[action.payload.key].filter((id) => id !== action.payload.value.id)
            state.selectedBrowseEventFilterObj[action.payload.key] = state.selectedBrowseEventFilterObj[action.payload.key].filter((obj) => obj.id !== action.payload.value.id)
            state.page = 1
        }
    },
    extraReducers: (builder) => {
        ///extrareducers for ungrouped browse items
        builder.addCase(getBrowseItemsProducts.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBrowseItemsProducts.fulfilled, (state, action) => {
            state.loading = false
            state.itemList = action.payload?.data
            state.totalCount = action.payload?.total_count
            state.totalPage = action.payload?.total_page
            state.error = ''
        })
        builder.addCase(getBrowseItemsProducts.rejected, (state, action) => {
            state.error = action.error.message
            state.loading = false
        })

        ///extrareducers for grouped browse items
        builder.addCase(getGroupedBrowseItems.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getGroupedBrowseItems.fulfilled, (state, action) => {
            state.loading = false
            state.itemList = action.payload?.data
            state.totalCount = action.payload?.total_count
            state.totalPage = action.payload?.total_page
            state.error = ''
        })
        builder.addCase(getGroupedBrowseItems.rejected, (state, action) => {
            state.error = action.error.message
            state.loading = false
        })
    }
})

export default browseItemsSlice.reducer;
export const { resetBrowseItems,  setBrowseBySelectedFilters, clearBrowseBySelectedFilters, clearAllBrowseBySelectedFilters} = browseItemsSlice.actions